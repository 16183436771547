$.lui("part", ".part_form_search", function(selector) {
    let refs = {
      autocompleteArea: `[data-ref="autocomplete-area"]`
    };

    let timeout;

    selector.on("input", `[data-input="search"]`, function() {
        clearTimeout(timeout);

        //selector.find(`[data-ref="autocomplete-area"]`).addClass("state--active");

        let query = $(this).val();

        let isSearching = 0;
        let searchIndicator = $('.wrp_form_autocomplete').find('input[name="searchSearching"]');
        if($('.wrp_form_autocomplete').is('.state--active') && searchIndicator.length && searchIndicator.val() === '1') {
            isSearching = 1;
        }

        if(query.length > 2) {
            $.ajax({
                url: $(this).attr('data-ajax-link'),
                data: {query: query, isSearching: isSearching},
                method: 'post',
                dataType: 'json'
            }).done(function (payload) {
                fn_ajaxHandler(payload);
            });
        } else {
            selector.find(`[data-ref="autocomplete-area"]`).removeClass("state--active");
        }
    });

    selector.on("click", `[data-input="search"]`, function() {
        let query = $(this).val();

        if(query.length < 2) {

            $.ajax({
                url: $(this).attr('data-ajax-link-initial'),
                data: {query: query},
                method: 'post',
                dataType: 'json'
            }).done(function (payload) {
                fn_ajaxHandler(payload);

                selector.find(`[data-ref="autocomplete-area"]`).addClass("state--active");
            });
        }
    });
    doc.on("click.search-autocomplete", function (e) {
        if (selector.length && selector.find(refs.autocompleteArea).hasClass("state--active")) {
            if ($(e.target).closest(selector).length === 0) {
                selector.find(refs.autocompleteArea).addClass("state--hiding");
                timeout = setTimeout(function() {
                    selector.find(refs.autocompleteArea).removeClass("state--active state--hiding");
                },300);
            }
        }
    });
});