$.fn.lui_slimselect = function (callback) {
    $(this).each(function () {
        let elm = $(this);

        var ajaxLink = $(this).attr('data-ajax-link');
        var ajaxLinkSet = $(this).attr('data-ajax-link-set');
        var labelSearching = $(this).attr('data-label-searching');
        var labelSearch = $(this).attr('data-label-search');
        var labelLowChars = $(this).attr('data-label-low-characters');
        var labelPlaceholder = $(this).attr('data-placeholder');
        var labelSearchPlaceholder = $(this).attr('data-search-placeholder');
        var itemId = $(this).attr('id');

        var slimSelectItem = new SlimSelect({
            select: '#' + itemId,
            searchingText: labelSearching,
            searchText: labelSearch,
            placeholder: labelPlaceholder,
            searchPlaceholder: labelSearchPlaceholder,
            onChange: (info) => {

                var ajaxData = Object.assign({},info.data);
                ajaxData.name = info.text;
                $.ajax({
                    url: ajaxLinkSet,
                    method: 'POST',
                    data: ajaxData,
                    cache: false
                });
            },
            ajax: function (search, callback) {
                if (search.length < 3) {
                    callback(labelLowChars);
                    return
                }

                $.ajax({
                    url: ajaxLink + '&s=' + search,
                    method: 'post',
                    dataType: 'json',
                    cache: false
                }).done(function (json) {
                    let data = [];
                    for (let i = 0; i < json.length; i++) {
                        data.push({text: json[i].name, value: json[i].id, data: { id_point: json[i].id, id_delivery: json[i].id_delivery, id_carrier: json[i].id_carrier}})
                    }

                    callback(data);
                });
            }
        });

    });
};