$.lui("lib_wsw", ".part_ui_wsw", function(selector){
    selector.find("table").each(function() {
        $(this).wrap("<div class='elm_table'></div>");
    });
    selector.find('iframe').filter(function(){
        return this.src.match(/youtube\.com/i);
    }).wrap("<div class='elm_video'></div>");
    selector.find('.part_ui_heading').each(function () {
        $(this).wrap("<div class='text--center mb--16'></div>")
    });
});
