$.lui("layout","#layout_header", function(selector) {
    let refs = {
        headerBanner: selector.find(`[data-ref="header-banner"]`)
    };

    if (localStorage.getItem("bannerClosed") !== `version-${refs.headerBanner.data("version")}` && refs.headerBanner.length) {
        selector.addClass("has--banner");
        refs.headerBanner.removeAttr("hidden");
    }

    selector.on("click", `[data-click="bannerClose"]`, function() {
        selector.removeClass("has--banner");
        refs.headerBanner.remove();
        localStorage.setItem("bannerClosed", `version-${refs.headerBanner.data("version")}`);
    });

    if (! $("#layout_nav").length) {
        selector.after('<nav id="layout_nav" class="ssm-nav"><div class="wrp_nav_head"></div><div class="wrp_nav_content"></div></nav>');

        $.importScript(cdnjs.touchswipe, function() {
            $('.ssm-nav').lui_ssm();
        });

        let layout_nav = $(document).find("#layout_nav"),
            logo = selector.find(".elm_header_logo").clone(),
            nav = selector.find(".elm_header_nav").clone();

        layout_nav.find(".wrp_nav_head").append(logo);
        layout_nav.find(".wrp_nav_content").append(nav);


        cssLoaded(function() {
            $.importScript(cdnjs.headroom, function(){
                let headroom = new Headroom(selector[0], {
                    offset: $("#layout_header").height()*2,
                    tolerance : {
                        up : 5,
                        down : 0
                    },
                    classes: {
                        "initial" : "headroom",
                        "pinned": "headroom--pinned",
                        "unpinned": "headroom--unpinned",
                        "top" : "headroom--top",
                        "notTop" : "headroom--not-top"
                    }
                });
                headroom.init();

                win.on("resize", function() {
                    headroom.destroy();
                    headroom.init();
                });
            });
        });
    }
});