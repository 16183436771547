$.lui("comp",".comp_package_basket",function (selector) {

    //data-add-voucher
    $(document).on('keypress',function(e) {
        if(e.which === 13) {
            if($('[data-voucher-code]').is(':focus')) {
                e.preventDefault();
                $('[data-add-voucher]').trigger('click');
            }
        }
    });

    selector.on("change", "[data-toggle] input", function() {
        let row = $(this).closest("[data-toggle]").next(".elm_form_row");
        if ($(this).prop("checked") === true) {
            row.slideDown(300);
        } else {
            row.slideUp(300);
        }
    });

    selector.on('change','input[type="number"][data-ajax-link]',function() {
        let ajaxLink = $(this).attr('data-ajax-link');
        let count = $(this).val();

        $.ajax({
            url: ajaxLink,
            data: {count: count},
            method: 'post',
            dataType: 'json'
        }).done(function (payload) {
            fn_ajaxHandler(payload);
        });
    });

    selector.on('click','[data-add-voucher]',function() {

        let url = $(this).attr('data-ajax-link');
        let code = $('input[data-voucher-code]').val();

        $.ajax({
            url: url,
            method: 'post',
            dataType: 'json',
            cache: false,
            data: {
                code: code
            }
        }).done(function (payload) {
            fn_ajaxHandler(payload);
        });

    });

    selector.on('change','[data-service-item]',function() {
        let ajaxLink = $(this).attr('data-ajax-link');
        let ajaxData = {};

        if($('[data-service-type="ZASILKOVNA"]').length && $('[data-service-type="ZASILKOVNA"]').prop('checked') === true) {
            let pointId = $('.packeta-selector-branch-id').val();
            let deliveryId = $('[data-service-type="ZASILKOVNA"]').attr('value');
            let pointName = $('input[name="pickup_point_name_' + deliveryId + '"]').val();

            ajaxData = {
                deliveryId: deliveryId,
                pointId: pointId,
                pointName: pointName
            };
        }

        $.ajax({
            url: ajaxLink,
            method: 'post',
            dataType: 'json',
            data : ajaxData,
        }).done(function (payload) {
            fn_ajaxHandler(payload, function() {
                showHideDeliveryText();
            });
        });
    });

    function showHideDeliveryText()
    {

        $('[data-service-item-text]').hide();

        let checkedItem = $('[data-service-item]:checked');

        if(checkedItem.length) {

            $('[data-service-item]:checked').each(function() {
                $(this).closest('.elm_item').find('[data-service-item-text]').show();
            });
        }
    }
    showHideDeliveryText();

    selector.on('change','[data-save-email]', function() {
        let email = $(this).val();
        $.ajax({
            data: {do: 'saveMail', email: email},
            cache: false
        });
    });

    selector.on('input','[data-fill]',function() {
        let elm = $(this);
        let type = $(this).data('fill');
        let val = $(this).val().trim();

        if(val.length < 2) {
            elm.closest(".part_autocomplete").removeClass("state--active");
            return;
        }

        let country = $(this).closest('.wrp_form_body').find('[data-fill-country]').val();
        let cityId = $(this).closest('.wrp_form_body').find('[data-fill="city"]').attr('data-fill-city-id');
        let url = $('.part_package_basket_form_data').attr('data-link-autocomplete');

        let ajaxData = {
            country: country,
            type: type,
            query: val,
            cityId: cityId
        };

        $.ajax({
            url: url,
            method: 'post',
            dataType: 'json',
            data : ajaxData,
        }).done(function (payload) {
            fn_ajaxHandler(payload, function() {
                if(val.length > 2 && payload.length > 0) {
                    elm.closest(".part_autocomplete").addClass("state--active");
                }
            });
        });
    });

    doc.off("click.fill-autocomplete");
    doc.on("click.fill-autocomplete", function (e) {
        let selector = $(".part_autocomplete");
        if (selector.length && selector.hasClass("state--active")) {
            if ($(e.target).closest(selector).length === 0) {
                selector.removeClass("state--active")
            }
        }
    });

    selector.on('click','[data-autocomplete-city]',function() {
        let cityId = $(this).attr('data-autocomplete-city');
        let name = $(this).text();
        $('[data-fill="city"]').val(name).attr('data-fill-city-id',cityId);
        $(this).closest(".part_autocomplete").removeClass("state--active");
        $(this).parent().html('');
    });

    selector.on('click','[data-autocomplete-street]',function() {
        let streetId = $(this).attr('data-autocomplete-street');
        let name = $(this).text();
        console.log(name);
        $('[data-fill="street"]').val(name).attr('data-fill-street-id',streetId);
        $(this).closest(".part_autocomplete").removeClass("state--active");
        $(this).parent().html('');
    });

});