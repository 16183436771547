$.fn.lib_flickity = function () {
    let selector = $(this);
    if (!html.hasClass("ie9")) {
        $.importScript(cdnjs.flickity, function () {
            cssLoaded(function () {
                selector.each(function () {
                    let elm = $(this);
                    let item_count = elm.children().length;
                    let options = elm.data("lib-flickity");
                    let nav = elm.parent().parent().find("[data-lib-flickity-nav]");
                    let counter = elm.parent().parent().find("[data-lib-flickity-counter]");

                    elm.on("ready.flickity", function (e) {
                        elm.find(".flickity-button").lui_ripple();
                        if (elm.find(".flickity-button[disabled]").length === 2) {
                            elm.addClass("flickity-nav-disabled");
                        }
                    });

                    if (typeof options["groupCells"] !== "undefined") {
                        if (win.width() < 768) {
                            options["groupCells"] = "25%"
                        }
                    }


                    let opptions_all = {
                        groupCells: (typeof options["groupCells"] !== "undefined") ? options["groupCells"] : "25%",
                        cellAlign: (typeof options["cellAlign"] !== "undefined") ? options["cellAlign"] : "left",
                        setGallerySize: true,
                        autoPlay: options["autoplay"],
                        pageDots: true,
                        wrapAround: (typeof options["wrapAround"] !== "undefined") ? options["wrapAround"] : true,
                        contain: true,
                        adaptiveHeight: false,
                        pauseAutoPlayOnHover: true,
                        prevNextButtons: true,
                        percentPosition: false
                    };

                    let slider = $(this).flickity(opptions_all);

                    elm.addClass("flickity-flex");

                    function fn_arrows() {
                        let items_lenght = slider.find(".flickity-slider").children().length;
                        let items_width = slider.find(".flickity-slider").children().outerWidth();
                        let width = slider.find(".flickity-slider").width();

                        if (width >= (items_lenght * items_width)) {
                            selector.find(".flickity-button").hide();
                        } else {
                            selector.find(".flickity-button").show();
                        }
                    }

                    fn_arrows();

                    win.off("resize.flickity");
                    win.on("resize.flickity", debounce(function () {
                        fn_arrows();
                        elm.removeClass("flickity-flex");
                        // elm.flickity("resize");
                        elm.addClass("flickity-flex");
                    }, 250));

                    if (typeof options["min"] !== "undefined") {
                        if (typeof options["min"]["desktop"] !== "undefined") {
                            if (parseInt(options["min"]["desktop"]) > parseInt(item_count)) {
                                slider.flickity('destroy');
                            }
                        }
                    }

                    if (typeof options["destroyDevices"] !== "undefined") {
                        if (win.width() < 960) {
                            slider.flickity('destroy');
                        }

                        const mediaQuery = window.matchMedia('screen and (min-width : 60em)');
                        mediaQuery.onchange = e => {
                            if (win.width() > 960) {
                                slider.flickity(opptions_all);
                            } else {
                                slider.flickity('destroy');
                            }
                        }
                    }

                    if (nav.length) {
                        nav.on("click", function () {
                            let direction = $(this).data("lib-flickity-nav");

                            if (direction === "prev") {
                                slider.flickity('previous');
                            } else {
                                slider.flickity('next');
                            }
                        });
                    }

                    if (counter.length) {
                        slider.on('change.flickity', function (event, index) {
                            counter.text(`${index + 1}/${slider.data("flickity").cells.length}`);
                        });
                    }
                });
            });
        });
    }
};