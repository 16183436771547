let priceRangeBackToDefaults;
let doCatalogFilter;
let timeoutFilter;

$.lui("part", ".part_filter", function(selector) {

    priceRangeBackToDefaults = function()
    {
        let defaultMin = $('input[name="priceMinDefault"]').val();
        let defaultMax = $('input[name="priceMaxDefault"]').val();
        $('[data-range-input="min"]').val(defaultMin).change();
        $('[data-range-input="max"]').val(defaultMax).change();
    };

    doCatalogFilter = function(elem, redrawFiltersOnly,page,pageFrom,pagingType,fromSession,callback)
    {

        let inDialog = 0;

        if(elem) {
            inDialog = parseInt(elem.closest('.part_filter').attr('data-in-filter'));
        }

        let filterValues = {};

        let selectorCatalogFilter = '.comp_products_filter [data-catalog-filter]';
        if(inDialog) {
            selectorCatalogFilter = '.wrp_dialog [data-catalog-filter]';
        }

        $(selectorCatalogFilter).each(function() {
            let name = $(this).attr('name');
            let value = $(this).val();

            filterValues[name] = value;
        });

        let selectorCatalogFilterCheck = '.comp_products_filter [data-catalog-filter-check]';
        if(inDialog) {
            selectorCatalogFilterCheck = '.wrp_dialog [data-catalog-filter-check]';
        }

        $(selectorCatalogFilterCheck).each(function() {
            if($(this).prop('checked') === true) {

                let name = $(this).attr('data-name');
                let value = $(this).val();
                if(typeof filterValues[name] === 'undefined') {
                    filterValues[name] = [];
                }
                filterValues[name].push(value);
            }
        });

        let sortType = $('.state--active[data-sort]').attr('data-sort');

        let searchQuery = null;
        searchQuery = $('.comp_products_filter').attr('data-search-query');

        let data = {
            filterData: filterValues,
            onlyFilters: (redrawFiltersOnly ? 1 : 0),
            page: (page ? page : 0),
            pageFrom: (pageFrom ? pageFrom : page),
            fromSession: (fromSession ? fromSession : 0),
            pagingType: (pagingType ? pagingType : 'default'),
            sortType: sortType,
            searchQuery: searchQuery,
            inDialog: inDialog
        };

        let dataUri = ['page=' + data.page, 'pageFrom=' + data.pageFrom];

        if(sortType) {
            dataUri.push('sortType=' + sortType);
        }

        for (let property in filterValues) {
            let itemData = filterValues[property];
            if(typeof itemData === 'string' || typeof itemData === 'number') {
                dataUri.push(property + '=' + itemData);
            } else {
                dataUri.push(property + '=' + itemData.join('-'));
            }
        }

        clearTimeout(timeoutFilter);

        timeoutFilter = setTimeout(function() {
            $('.comp_products_filter').addClass("state--loading");
            if(pagingType !== 'append') {
                if (win.width() > 960) {
                    $('html, body').animate({
                        scrollTop: $('.comp_products_filter').offset().top - 80
                    }, 500);
                }
            }
            $.ajax({
                url: selector.attr('data-ajax-link'),
                method: 'post',
                dataType: 'json',
                cache: false,
                data: data
            }).done(function (payload) {
                window.history.replaceState("", "", '?' + dataUri.join('&'));
                fn_ajaxHandler(payload,function() {
                    $('.comp_products_filter').removeClass("state--loading");
                    if(typeof callback !== 'undefined') {
                        callback();
                    }
                });
            });
        }, 750);
    };

    let range = selector.find(".part_ui_range");
    if (range.length) {
        range.each(function () {
            let elm = $(this);
            $.importScript(cdnjs.autoNumeric, function () {
                $.importScript(cdnjs.noui, function () {
                    elm.lib_noui({
                        elm_min: elm.find(`[data-range-input="min"]`),
                        elm_max: elm.find(`[data-range-input="max"]`),
                        elm_min_hidden: elm.find(`[data-range-input="min"]`).next(),
                        elm_max_hidden: elm.find(`[data-range-input="max"]`).next(),
                        unit: elm.data("unit")
                    });

                    elm.on("change", `[data-range-input="min"]`, function () {
                        let min = $(this).val();
                        let max = elm.find(`[data-range-input="max"]`).val();
                        elm.closest(".elm_item").find("[data-range-value]").text(`${min.replace(",-", "")} - ${max.replace(",-", "")}`);
                    });
                    elm.on("change", `[data-range-input="max"]`, function () {
                        let min = elm.find(`[data-range-input="min"]`).val();
                        let max = $(this).val();
                        elm.closest(".elm_item").find("[data-range-value]").text(`${min.replace(",-", "")} - ${max.replace(",-", "")}`);
                    });
                });
            });
        });
    }

    selector.on('change','[data-do-filter-change]',function() {
        doCatalogFilter($(this),0,1);
    });

    selector.on('change','[data-catalog-filter-check]',function() {
        doCatalogFilter($(this),0,1);
    });

    selector.on("click", "[data-toggle]", function() {
        let elm = $(this);
        if (!elm.hasClass("state--active")) {
            elm.addClass("state--active");
            elm.next().slideDown(300);
        } else {
            elm.removeClass("state--active");
            elm.next().slideUp(300);
        }
    });
});