$.lui("comp",".comp_products_filter",function (selector) {

    selector.on('click','[data-sort]',function(e) {
        e.preventDefault();
        if(!$(this).hasClass('state--active')) {
            $('[data-sort]').removeClass('state--active');
            $(this).addClass('state--active');
            doCatalogFilter(null,0,1);
        }
    });

    selector.on('click','a[data-do-filter],button[data-do-filter]',function(e) {
        e.preventDefault();
        let pagingType = 'default';
        let loadingButton;
        if($(this).is('[data-append]')) {
            pagingType = 'append';

            loadingButton = $(this);
            loadingButton.addClass('state--loading').attr('disabled', true);
        }

        let page = 1;
        if($(this).is('[data-page]')) {
            page = $(this).attr('data-page');
        }

        let pageFrom = null;

        if($(this).is('[data-page-from]')) {
            pageFrom = $(this).attr('data-page-from');
        } else {
            pageFrom = page;
        }

        doCatalogFilter(null,0,page,pageFrom,pagingType,null,function() {
            if(loadingButton) {
                loadingButton.removeClass('state--loading').attr('disabled', false);
            }
        });
    });

});