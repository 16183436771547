$.fn.lib_noui = function (options) {
    $(this).each(function () {

        let fn = {
            this: $(this),
            slider: $(this).find("[data-range-slider]"),
            elm_min: options.elm_min,
            elm_min_hidden: options.elm_min_hidden,
            elm_max: options.elm_max,
            elm_max_hidden: options.elm_max_hidden,
            unit: options.unit,
            currencyCourse: $(this).attr('data-currency-course'),
            currencyDecimals: $(this).attr('data-decimals'),
            currencyPlacement: $(this).attr('data-currency-placement'),
        };

        if (typeof fn.unit === "undefined") {
            fn.unit = "";
        }

        let autoNumeric_min;
        let autoNumeric_max;

        if (typeof fn.elm_min !== "undefined") {
            autoNumeric_min = new AutoNumeric(fn.elm_min[0], {
                digitGroupSeparator: ' ',
                decimalPlaces: parseInt(fn.currencyDecimals),
                minimumValue: "0",
                maximumValue: "9999999999",
                unformatOnHover: false,
                currencySymbol : fn.unit+' ',
                currencySymbolPlacement: fn.currencyPlacement,
                watchExternalChanges: true
            });
        }

        if (typeof fn.elm_max !== "undefined") {
            autoNumeric_max = new AutoNumeric(fn.elm_max[0], {
                digitGroupSeparator: ' ',
                decimalPlaces: parseInt(fn.currencyDecimals),
                minimumValue: "0",
                maximumValue: "9999999999",
                unformatOnHover: false,
                currencySymbol : fn.unit+' ',
                currencySymbolPlacement: fn.currencyPlacement,
                watchExternalChanges: true
            });
        }

        if (fn.slider.length) {
            let min_val;
            let max_val;
            if (typeof fn.elm_min !== "undefined") {
                min_val = autoNumeric_min.rawValue;
            }
            if (typeof fn.elm_max !== "undefined") {
                max_val = autoNumeric_max.rawValue;
            }

            let slider_min = fn.slider.data("min"),
                slider_max = fn.slider.data("max"),
                slider_step = fn.slider.data("step");

            if (fn.this.hasClass("mod--type-single")) {
                noUiSlider.create(fn.slider[0], {
                    start: max_val,
                    step: slider_step,
                    connect: [true,false],
                    range: {
                        'min': slider_min,
                        'max': slider_max
                    }
                });

                fn.elm_max.on('change', function () {
                    if ($(this).val() !== "") {
                        fn.slider[0].noUiSlider.set(autoNumeric_max.rawValue);
                    }
                });
            } else {
                noUiSlider.create(fn.slider[0], {
                    start: [min_val, max_val],
                    step: slider_step,
                    connect: true,
                    range: {
                        'min': slider_min,
                        'max': slider_max
                    }
                });

                fn.elm_max.on('change', function () {
                    if ($(this).val() !== "") {
                        fn.slider[0].noUiSlider.set([autoNumeric_min.rawValue, autoNumeric_max.rawValue]);
                    }
                });

                fn.elm_min.on('change', function () {
                    if ($(this).val() !== "") {
                        fn.slider[0].noUiSlider.set([autoNumeric_min.rawValue, autoNumeric_max.rawValue]);
                    }
                });
            }

            fn.slider[0].noUiSlider.on('update', function (values, handle) {
                let value = values[handle];
                if (!handle) {
                    if (typeof fn.elm_min_hidden !== "undefined") {
                        fn.elm_min_hidden[0].value = Math.round(value);
                    }

                    if (typeof fn.elm_min !== "undefined") {
                        autoNumeric_min.set(Math.round(value));
                    }
                }
                if (handle || typeof fn.elm_min === "undefined") {
                    if (typeof fn.elm_max_hidden !== "undefined") {
                        fn.elm_max_hidden[0].value = Math.round(value);
                    }

                    if (typeof fn.elm_max !== "undefined") {
                        autoNumeric_max.set(Math.round(value));
                    }
                }
            });


            fn.slider[0].noUiSlider.on('change', function (values, handle) {
                if (!handle) {
                    if (typeof fn.elm_min !== "undefined") {
                        if (autoNumeric_min.rawValue !== "") {
                            fn.elm_min.trigger('change');
                        }
                    }
                    if (typeof fn.elm_min_hidden !== "undefined") {
                        fn.elm_min_hidden.trigger('change');
                    }
                }
                if (handle || typeof fn.elm_min === "undefined") {
                    if (typeof fn.elm_max !== "undefined") {
                        if (autoNumeric_max.rawValue !== "") {
                            fn.elm_max.trigger('change');
                        }
                    }
                    if (typeof fn.elm_max_hidden !== "undefined") {
                        fn.elm_max_hidden.trigger('change');
                    }
                }
            });
        }
    });
};