(function($) {
    lui_dialog.init(lui_dialog_callback);

    $.lui("lib_ripple", ".part_ui_btn", function(selector) {
        selector.lui_ripple();
    });

    $.lui("lib_tabs", "[data-lib-tabs]", function(selector) {
        selector.lui_tabs();
    });

    $.lui("lib_flickity", "[data-lib-flickity]", function(selector) {
        selector.lib_flickity();
    });

    $.lui("lib_slimselect", "[data-lib-slimselect]", function(selector) {
        $.importStyle(cdnjs.slimselect_css);
        $.importScript(cdnjs.slimselect, function() {
            selector.lui_slimselect();
        });
    });

    $.lui("lib_autosize","[data-lib-autosize]", function (selector) {
        $.importScript(cdnjs.autosize, function () {
            autosize(selector);
        });
    });

    $.lui("lib_tippy", "[data-lib-tippy]", function(selector) {
        $.importScript(cdnjs.popper, () => {
            $.importScript(cdnjs.tippy, () => {
                selector.each(function() {
                    let element = $(this)[0];
                    let options;
                    let content;
                    let template;
                    let interactive = true;
                    let maxWidth = 350;
                    let trigger = "mouseenter click";
                    let placement = "top";

                    options = element.getAttribute("data-lib-tippy").replace(/\s/g,"").split(",");

                    if (options[0].length > 0) {
                        if (options[0].includes("dropdown")) {
                            trigger = "click";
                            placement = "bottom-end";
                            template = document.querySelector(options[1]);
                        }
                        else {
                            maxWidth = 'none';
                            template = document.querySelector(options[0]);
                        }

                        if(template) {
                            content = template.innerHTML;

                            if (typeof options[1] !== "undefined") {
                                content = content.replace("{this}", options[1]);
                            }
                        }
                    } else {
                        content = `${element.getAttribute("aria-label")}`;
                    }

                    tippy(element, {
                        placement: placement,
                        maxWidth: maxWidth,
                        content: content,
                        allowHTML: true,
                        theme: 'light',
                        trigger: trigger,
                        arrow: false,
                        interactive: interactive,
                        animation: "scale",
                        flip: true,
                        inertia: true,
                        distance: 10,
                        zIndex: 1000
                    });
                });
            });
        })
    });

    $.lui("lib_gallery", "[data-lib-fancybox]", function(selector) {
        bodyLoaded(function(){
            if ($(selector).find(".lib--fancybox-item").length) {
                $.importStyle(cdnjs.fancybox_css);
                $.importScript(cdnjs.fancybox, function(){
                    $(selector).each(function(){
                        let id = $(this).data("lib-fancybox");
                        $(this).fancybox({
                            selector: `[data-lib-fancybox="${id}"] .lib--fancybox-item`,
                            transitionEffect: "slide",
                            animationEffect: "zoom",
                            hideScrollbar: false,
                            buttons: [
                                "close"
                            ]
                        });
                    });

                    $("[data-lib-fancybox-open]").on("click", function(){
                        let id = $(this).data("lib-fancybox-open");
                        $(`[data-lib-fancybox="${id}"] .col:first-of-type .lib--fancybox-item`).trigger("click")
                    })
                });
            }
        });
    });

    doc.on('click','[data-cancel-filters]',function(e) {
        e.preventDefault();
        $('[data-sort]').removeClass('state--active');
        $('[data-sort="recommended"]').addClass('state--active');

        $('[data-catalog-filter-check]').prop('checked',false);
        priceRangeBackToDefaults();

        doCatalogFilter($(this),0,1);
    });

    doc.on('submit','form.part_form_search', function(e) {
            if(!$(this).find('[name="search"]').val().length) {
                e.preventDefault();
            }
    });

    doc.on("click", `[data-doc-click="basket-add"]`, function(e){
        $("body").append('<div class="layout_summary_circle"></div>');

        let summary_circle =  $(".layout_summary_circle");
        let basket = $("#layout_header .wrp_header_body .container");
        let attributes = $(this).data("attributes");
        let left;

        if ($("#layout_header").hasClass("headroom--not-top") && win.width() > 960) {
            basket = $("#layout_header .wrp_header_body .container");
            left = basket.offset().left + basket.width() - 24;
        } else {
            basket = $("#layout_header .icon--paper-bag");
            left = basket.position().left + 24;
        }

        summary_circle.css({
            "top":  e.clientY + "px",
            "left":  e.clientX + "px"
        });

        summary_circle.animate({
            top: basket.position().top + 24,
            left: left,
            width: 16,
            height: 16,
            opacity: 0.1,
        }, 800, function () {
            summary_circle.remove();
        });

        if (typeof attributes !== "undefined") {
            $.ajax({
                dataType: "json",
                data: {ajax: +new Date},
                url: attributes["dialog"]
            }).done(function(data) {
                setTimeout(function(){
                    lui_dialog.open(data.dialog, lui_dialog_callback);
                },500);
            });
        }
    });

    (function fn_ajaxEvents() {
        doc.on('click', '[href].ajax', function (e) {
            e.preventDefault();
            let el = $(this);

            $.ajax({
                url: el.attr('href'),
                data: {ajax: +new Date},
                dataType: "json",
            }).done(function (payload) {
                fn_ajaxHandler(payload);
            });
        });

        if (typeof HTMLFormElement.prototype.reportValidity !== "undefined") {
            doc.on("click", "form[novalidate] button[type=submit]", function(e) {
                let form = $(this).closest("form");
                e.preventDefault();

                if (form[0].reportValidity()) {
                    form.trigger('submit');
                } else {
                    form.find(".part_ui_input").each(function () {
                        if (!$(this).filter("[data-no-validation]").length && !$(this).hasClass("state--invalid")) {
                            $(this).validateInput(true);
                        }
                    });

                    form.find(".part_ui_select.state--placeholder select[required]").each(function () {
                        $(this).parent().addClass("state--invalid");
                    });
                }
            });
        }

        doc.on('submit', 'form.ajax,form[data-component-ajax]', function (e) {
            e.preventDefault();

            let frm = $(this),
                formData = new FormData($(this)[0]);

            frm.find('button[type=submit]').addClass('state--loading').attr('disabled', true);

            $.ajax({
                method: frm.attr('method'),
                url: frm.attr('action'),
                data: formData,
                processData: false,
                contentType: false,
                dataType: "json",
            }).done(function (payload) {
                fn_ajaxHandler(payload,function() {
                    if(typeof payload.dialog !== 'undefined' && payload.dialog.length) {
                        lui_dialog.open(payload.dialog);
                    }
                });
            }).always(function () {
                frm.find('button[type=submit]').removeClass('state--loading').attr('disabled', false);
            });
        });

        doc.on('click', 'a[data-component-ajax]', function (e) {
            e.preventDefault();
            let url = $(this).attr('data-href');
            if (!url || !url.length) {
                url = $(this).attr('href');
            }

            if (!url || !url.length) {
                console.log('no href attribute for ajax component link');
                return;
            }

            let _self = $(this);
            if($(this).is('[data-state-loading]')) {
                _self.addClass('state--loading');
            }

            $.ajax({
                url: url,
                method: 'post',
                dataType: 'json'
            }).done(function (payload) {
                fn_ajaxHandler(payload, function () {
                    console.log(payload.dialog);
                    if(typeof payload.dialog !== 'undefined' && payload.dialog.length) {
                        lui_dialog.open(payload.dialog);
                    }
                    _self.removeClass('state--loading');
                });
            });
        });
    })();

    (function fn_pictureFill() {
        if (document.createElement('picture').toString().indexOf("HTMLPictureElement") === -1 && !html.hasClass("ie")) {
            $.importScript(cdnjs.picturefill);
        }
    })();

    (function fn_gridFill() {
        if (typeof html[0].style.grid !== 'string') {
            html.addClass("no-grid");
        }
    })();

    (function fn_cookieConsent() {
        if ($(".part_cookie_consent").length || $(".part_form_cookies").length) {
            $.importScript(cdnjs.cookieconsent);
        }
    })();

    function fn_reCaptcha() {
        $.lui("lib_reCaptcha", "[data-lib-recaptcha]", function (selector) {
            selector.lib_reCaptcha("lib-recaptcha");
        });
    }

    setTimeout(fn_reCaptcha, 2500);

    setInterval(fn_reCaptcha, 150000);
})(jQuery);