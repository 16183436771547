$.lui("comp",".comp_video",function (selector) {
    let elm = selector[0];
    let video_wrap = elm.querySelector("[data-ref-video]"),
        video = video_wrap.querySelector("video");

    video.addEventListener("ended",()=>{
        video_wrap.classList.remove("state--playing");
        video.currentTime = 0;
        video.removeAttribute("controls");
    });
    video.addEventListener("pause",()=>{
        video.removeAttribute("controls");
        video_wrap.classList.remove("state--playing");
    });
    video.addEventListener("play",()=>{
        video.setAttribute("controls",true);
        video_wrap.classList.add("state--playing");
    });

    video_wrap.addEventListener("click",()=>{
        if(video.paused) {
            video.play();
        }
        else {
            video.pause();
        }
    })
});