$.lui("comp", ".comp_head", function(selector) {
    let refText = selector.find(`[data-ref="text"]`);

    if (refText.length) {
        refText.height(refText.find("p:first-of-type").height());

        win.off("resize.headText");
        win.on("resize.headText", function(){
            refText.height(refText.find("p:first-of-type").height());
        });

        selector.on("click", `[data-click="showMore"]`, function() {
            let elm = $(this);
            let text = elm.find("[data-text]");

            refText.addClass("transition");

            if (!elm.hasClass("state--active")) {
                elm.addClass("state--active");
                text.text(text.data("text")[1]);
                refText.height(refText.children().height())
            } else {
                elm.removeClass("state--active");
                text.text(text.data("text")[0]);
                refText.height(refText.find("p:first-of-type").height());
            }
        });
    }
});