let $ = jQuery,
    doc = $(document),
    win = $(window),
    html = $("html"),
    body = $("body"),
    lang = html.attr('lang');

window.lui = {
    layout: {},
    comp: {},
    part: {},
    lib: {}
};

if ('serviceWorker' in navigator && location.protocol === "https:") {
    window.addEventListener('load', function() {
        if (!html.hasClass("no-sw")) {
            navigator.serviceWorker.register('/sw.js').catch(function(e) {
                console.error('Error during service worker registration:', e);
            });
        } else {
            navigator.serviceWorker.getRegistrations().then(function(registrations) {
                if (registrations.length > 0) {
                    for(let registration of registrations) {
                        registration.unregister()
                    }
                }
            });
        }
    });
}

(function preloadPage() {
    if (document.body.classList.contains("body--preload")) {
        cssLoaded(function(){
            document.body.classList.remove("body--preload");
            setTimeout(function () {
                document.body.classList.add("body--loaded");
            }, 300);
        });
    }
})();

(function preloadFont() {
    if (typeof WebFontConfig === "undefined" && document.querySelector("[data-loadcss]") !== null) {
        if (typeof document.fonts !== "undefined") {
            document.fonts.ready.then(function () {
                document.documentElement.classList.add("wf-active");
            });
            setTimeout(()=> {
                if(!document.documentElement.classList.contains("wf-active")) {
                    document.documentElement.classList.add("wf-active");
                }
            },500);
        } else {
            document.documentElement.classList.add("wf-active");
        }
    }
})();