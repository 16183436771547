$.lui("comp",".comp_products_detail",function (selector) {
    selector.on('change','[data-product-variant]',function() {

        let variantId;
        if($(this).is('select')) {
            variantId = $(this).val();
        } else {
            variantId = $('[data-product-variant]:checked').val();
        }

        let url = $(this).attr('data-ajax-link');

        $.ajax({
            url: url,
            method: 'post',
            dataType: 'json',
            data: {
                variantId: variantId
            },
            cache: false
        }).done(function (payload) {
            fn_ajaxHandler(payload, function () {
                ScrollReveal().sync();

            });
        });
    });

    let chart = selector[0].querySelector(`[data-target="comp_products_detail.chart"]`);
    if(chart !== null) {
        let data = JSON.parse(chart.dataset.chart);
        $.importScript(cdnjs.chartjs,()=>{
            let radarChart = new Chart(chart,{
                type: "radar",
                data: {
                    labels: data["labels"],
                    datasets: [{
                        data: data["data"],
                        backgroundColor: "rgba(209,169,101,0.4)",
                        pointBorderWidth: 2,
                        pointBorderColor: "rgba(209,169,101,0.6)"
                    }]
                },
                options: {
                    tooltips: {
                        enabled: false
                    },
                    scale: {
                        angleLines: {
                            color: "rgba(54,28,16,0.2)"
                            // display: false
                        },
                        gridLines: {
                            color: "rgba(54,28,16,0.2)",
                            display: false
                        },
                        ticks: {
                            suggestedMin: 0,
                            display: false
                        }
                    },
                    legend: {
                        display: false
                    }
                }
            })
        })
    }

    selector.on("click",".elm_detail_image",function (e) {
        let elm = $(this),
            video = elm.find("video")[0];
    })

    let video = selector.find(".elm_detail_image video");

    if(video.length) {
        let video_elm = video[0];
        let video_wrap = video.closest(".elm_detail_image")[0];

        video_elm.addEventListener("ended",()=>{
            video_wrap.classList.remove("state--playing");
            video_elm.currentTime = 0;
            video_elm.removeAttribute("controls");
        });
        video_elm.addEventListener("pause",()=>{
            video_elm.removeAttribute("controls");
            video_wrap.classList.remove("state--playing");
        });
        video_elm.addEventListener("play",()=>{
            video_elm.setAttribute("controls",true);
            video_wrap.classList.add("state--playing");
        });

        video_wrap.addEventListener("click",(e)=>{
            if(video_elm.paused) {
                video_elm.play();
            }
            else {
                video_elm.pause();
            }
        })
    }
});